
@media only screen and (max-width: 767px) {
    .header {
        background-attachment: scroll;
    }

    .navbar-default {
        background-color: #FFF !important;
        border-color: #e6e6e6 !important;
    }
}